import { Col, Container, Row } from 'react-bootstrap'

function NoMatch() {
	return (
		<Container>
			<Row className="mt-3">
				<Col>
					<p>No matching route.</p>
				</Col>
			</Row>
		</Container>
	)
}

export default NoMatch
